<template>
  <header
    class="
      text-black
      sticky
      top-0
      z-20
      flex
      h-14
      items-center
      justify-between
      space-x-6
      bg-neutral-200
      px-3
      transition-all
      duration-300
      md:h-16 md:px-6
    "
  >
    <!-- Desktop menu -->
    <nav class="hidden uppercase lg:flex w-full h-full">
      <div>
        <ul class="flex h-full">
          <li class="px-3">
            <a
              href="https://www.stanleybet.ro/pariuri-sportive"
              class="
                flex
                h-full
                py-
                items-center
                whitespace-nowrap
                border-b-2 border-b-transparent
                font-medium
                transition-all
                duration-300
                ease-in-out
                hover:border-b-primary-500 hover:text-primary-500
              "
            >
              <span title="sport">Sport</span>
            </a>
          </li>
          <li class="px-3">
            <a
              href="https://www.stanleybet.ro/pariuri-live"
              class="
                flex
                h-full
                items-center
                whitespace-nowrap
                border-b-2 border-b-transparent
                font-medium
                transition-all
                duration-300
                ease-in-out
                hover:border-b-primary-500 hover:text-primary-500
              "
            >
              <span title="live">Live</span>
            </a>
          </li>
          <li class="px-3">
            <a
              href="https://www.stanleybet.ro/casino"
              class="
                flex
                h-full
                items-center
                whitespace-nowrap
                border-b-2 border-b-transparent
                font-medium
                transition-all
                duration-300
                ease-in-out
                hover:border-b-primary-500 hover:text-primary-500
              "
            >
              <span title="casino">Casino</span>
            </a>
          </li>
          <li class="px-3">
            <a
              href="https://www.stanleybet.ro/casino-live"
              class="
                flex
                h-full
                items-center
                whitespace-nowrap
                border-b-2 border-b-transparent
                font-medium
                transition-all
                duration-300
                ease-in-out
                hover:border-b-primary-500 hover:text-primary-500
              "
            >
              <span title="casino live">Casino Live</span>
            </a>
          </li>
          <li class="px-3">
            <a
              href="https://www.stanleybet.ro/virtuale"
              class="
                flex
                h-full
                items-center
                whitespace-nowrap
                border-b-2 border-b-transparent
                font-medium
                transition-all
                duration-300
                ease-in-out
                hover:border-b-primary-500 hover:text-primary-500
              "
            >
              <span title="virtuale">Virtuale</span>
            </a>
          </li>
          <li class="px-3">
            <a
              href="https://www.stanleybet.ro/loterii"
              class="
                flex
                h-full
                items-center
                whitespace-nowrap
                border-b-2 border-b-transparent
                font-medium
                transition-all
                duration-300
                ease-in-out
                hover:border-b-primary-500 hover:text-primary-500
              "
            >
              <span title="loterii">Loterii</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <mobile-navigation></mobile-navigation>
    <!-- Stanleybet logo -->
    <div class="flex w-full justify-start lg:justify-center">
      <a href="https://www.stanleybet.ro" class="logo" title="Stanleybet">
        <svg
          viewBox="0 0 264 46"
          role="img"
          aria-labelledby="logoTitleDesktop"
          class="fill-primary-500 max-w-full w-48 hidden lg:block"
        >
          <title id="logoTitleDesktop">Stanleybet</title>
          <path
            d="M21.6 1C9.7 1 0 10.6 0 22.4c0 4.8 1.6 9.2 4.3 12.8 1.3-.5 2.9-1.1 4.6-1.8 6.7-2.9
            14.6-6.3 15.4-9.1.8-2.8-1.1-2.5-4.6-2-3.6.5-10.2 2.5-9.9-3.3.3-6 1.8-8
            17.6-8.7v2.8s-13.3.2-13.3 4.5c.1 3.9 8.6-.6 11.8.9 3.2 1.6 2.6 6.2-2.6 10.1-3.9 3-12.9
            5.8-18.5 7.2 4 4.8 10 7.9 16.7 7.9 1.9 0 3.8-.3
            5.6-.8l3.8-40c-2.8-1.1-6-1.9-9.3-1.9M32.4 3.9l-3.7 38.7c8.4-2.9 14.4-10.9 14.4-20.2
            0-7.9-4.3-14.8-10.7-18.5"
          ></path>
          <path
            d="M104.1 15.2s-6.9.1-10.5 3.1l3.7 2.4s2.5-2.1 6.5-2.1c3.4 0 4.5 2.2 3.2 4 0
            0-16.2-.7-17 6.6-.7 6.9 11.4 5.4 13.9 3.6v1.3l5.9.1.2-2.4 2.9-9s3-8.1-8.8-7.6zm-7.9
            14.4c-1.3-4.1 9.5-4 9.5-4-1.2 6.9-8.8 6.2-9.5 4zM139 22l-4
            12.2h-6.7l3.3-10c2.5-6-1.6-5.4-1.6-5.4-2.1.1-4.5.7-6.7 2.5l-4.5
            12.9h-6.4l6.2-18.6h6l-.3 2.2c4.1-2.7 8.2-2.3 8.2-2.3 8.9.4 6.5 6.5 6.5 6.5zM58.2
            14.3c-.7 2.8 4.5 3.8 4.5 3.8 11.7 3.5 11.6 7.7 11.5 9.1-.1 2.4-2.7 7.3-12.8
            7.2-12.9-.1-14-6.4-14-6.4l6.3-1.5c.9 1.5 2 3.5 7.4 3.8 2.5.1 5.6-.7 5.6-2.8
            0-2.1-.5-2.8-7.1-5-6.8-2.2-8.6-4.7-8.6-7s2-7.4 11.9-7.4c9.5-.1 12.6 5.3 12.6 5.3l-5.7
            1.8s-1.4-2.4-5.7-3.1c-1.9-.5-5.4 0-5.9 2.2zM153.1 8.5l-8.5 25.7h-6.7l8.7-25.8
            6.5.1zM235 15.2c-11.2-.1-13.5 8.8-13.1 12.5.4 3.5 2.5 6.9 11 6.9 7.4 0 10-3.8
            10-3.8l-4.6-2.1s-1.9 2.2-5.8 2.2c-4.3 0-5-3.4-4.1-5.4h16.5s2.7-10.2-9.9-10.3zm4.2
            7.4H229s1.1-4 6.1-4c4.9 0 4.1 4 4.1 4zM88.6 30.6L87.5 34s-.9.5-4.7.5c-3.9
            0-6.8-1.8-5.4-6.3 1.4-4.5 3-9 3-9h-4l1.3-3.8h4.1l1.8-5.7h6.7l-1.9 5.7h3.9l-1.4
            3.8H87l-2.9 8.9s-1.3 2.7 1.5 2.6c2.5-.1 3-.1 3-.1zM197.1 15.5S184.4 34 182.3 36.8c-2.2
            2.8-4.3 3.8-7.6 3.8h-5.8l1.5-3.8h1.3c1.3.1 4.5.3
            5.9-2.2.6-1-.1-3.9-.1-3.9l-3.4-15.2h6.2l2 12.5 7.7-12.5h7.1zM244.8 25.6zM253.9
            28.1s-1.3 2.7 1.5 2.6c2.8-.1 3.2-.1 3.2-.1l-1.1 3.4s-.9.6-4.7.6c-3.8 0-6.8-1.8-5.4-6.3
            1.4-4.5 3-9 3-9h-4l1.3-3.8h4.1l1.8-5.7h6.7l-1.9 5.7h3.9l-1.4 3.8H257l-3.1 8.8zM205.1
            17.7l2.6-9.1-6.1-.1-8.9 25.7h5.2l2-2.6s1.5 2.8 7.7 2.8 11.9-4.1
            12.7-10.5c.8-7.2-6.7-11.6-15.2-6.2zm9 5.8c-.6 3.4-3.1 7.4-7.7 7.4-4.5
            0-5.3-2.1-5.3-2.1l2.6-7.3c2.5-2.1 4.6-2.1 6.2-2.1 2.3-.1 4.8.9 4.2 4.1z"
          ></path>
          <path
            d="M170.9 30.7l-4.6-2.1s-1.9 2.2-5.8 2.2c-4.3
            0-5-3.4-4.1-5.4h16.5s2.8-10.3-9.8-10.4c-11.2-.1-13.5 8.8-13.1 12.5.4 3.5 2.5 6.9 11 6.9
            7.3.2 9.9-3.7 9.9-3.7zm-7.8-12.1c5 0 4.1 4 4.1 4H157s1.1-4 6.1-4z"
          ></path>
        </svg>
        <svg
          viewBox="0 0 264 46"
          role="img"
          aria-labelledby="logoTitle"
          class="fill-primary-500 max-w-full w-48 lg:hidden"
        >
          <title id="logoTitle">Stanleybet</title>
          <path
            d="M21.6 1C9.7 1 0 10.6 0 22.4c0 4.8 1.6 9.2 4.3 12.8 1.3-.5 2.9-1.1 4.6-1.8 6.7-2.9
            14.6-6.3 15.4-9.1.8-2.8-1.1-2.5-4.6-2-3.6.5-10.2 2.5-9.9-3.3.3-6 1.8-8
            17.6-8.7v2.8s-13.3.2-13.3 4.5c.1 3.9 8.6-.6 11.8.9 3.2 1.6 2.6 6.2-2.6 10.1-3.9
            3-12.9 5.8-18.5 7.2 4 4.8 10 7.9 16.7 7.9 1.9 0 3.8-.3
            5.6-.8l3.8-40c-2.8-1.1-6-1.9-9.3-1.9M32.4 3.9l-3.7 38.7c8.4-2.9 14.4-10.9 14.4-20.2
            0-7.9-4.3-14.8-10.7-18.5"
          ></path>
        </svg>
      </a>
    </div>
    <!-- </div> -->
    <!-- Social media holder -->
    <div class="flex justify-end w-full">
      <a
        target="_blank"
        href="https://www.facebook.com/stanleybet.ro"
        class="mr-3"
        aria-label="Vizitați pagina noastră de facebook"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          id="Group_45286"
          data-name="Group 45286"
          width="25"
          height="25"
          viewBox="0 0 24.675 24.674"
        >
          <defs>
            <clipPath id="clip-path">
              <rect
                id="Rectangle_7990"
                data-name="Rectangle 7990"
                width="24.675"
                height="24.674"
                fill="none"
              />
            </clipPath>
          </defs>
          <g
            id="Group_45285"
            data-name="Group 45285"
            transform="translate(0 0)"
            clip-path="url(#clip-path)"
          >
            <path
              id="Path_58562"
              data-name="Path 58562"
              d="M24.675,12.337A12.337,12.337,0,1,0,10.41,
              24.525V15.9H7.277V12.337H10.41V9.619c0-3.092,1.842-4.8,4.66-4.8a18.993,18.993,0,0,1,
              2.762.241V8.1H16.276a1.783,1.783,0,0,0-2.011,1.926v2.315h3.422L17.14,
              15.9H14.265v8.621a12.34,12.34,0,0,0,10.41-12.188"
              transform="translate(0 0)"
              fill="#337fff"
            />
            <path
              id="Path_58563"
              data-name="Path 58563"
              d="M20.3,18l.547-3.566H17.423V12.115a1.783,1.783,0,0,1,2.011-1.927h1.556V7.152a19.006
              ,19.006,0,0,0-2.762-.241c-2.818,0-4.66,1.708-4.66,
              4.8v2.718H10.435V18h3.133v8.621a12.465,12.465,0,0,0,3.855,0V18Z"
              transform="translate(-3.158 -2.092)"
              fill="#fff"
            />
          </g>
        </svg>
      </a>
      <a
        target="_blank"
        href="https://www.instagram.com/stanleybet_romania/"
        class="mr-3"
        aria-label="Vizitați pagina noastră de instagram"
      >
        <svg
          class="rounded-full"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          id="Group_45291"
          data-name="Group 45291"
          width="25"
          height="25"
          viewBox="0 0 24.675 24.675"
        >
          <defs>
            <clipPath id="clip-path">
              <path
                id="Path_58564"
                data-name="Path 58564"
                d="M12.337,0A12.337,12.337,0,1,0,24.675,12.338,12.337,12.337,0,0,0,12.337,0Z"
                fill="none"
              />
            </clipPath>
            <linearGradient
              id="linear-gradient"
              x1="1.04"
              y1="0.714"
              x2="0.165"
              y2="0.855"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#fbe18a" />
              <stop offset="0.001" stop-color="#fbe18a" />
              <stop offset="0.209" stop-color="#fcbb45" />
              <stop offset="0.376" stop-color="#f75274" />
              <stop offset="0.524" stop-color="#d53692" />
              <stop offset="0.739" stop-color="#8f39ce" />
              <stop offset="1" stop-color="#5b4fe9" />
            </linearGradient>
            <clipPath id="clip-path-2">
              <rect
                id="Rectangle_7992"
                data-name="Rectangle 7992"
                width="24.675"
                height="24.675"
                fill="none"
              />
            </clipPath>
          </defs>
          <g
            id="Group_45288"
            data-name="Group 45288"
            transform="translate(0 0)"
          >
            <g
              id="Group_45287"
              data-name="Group 45287"
              clip-path="url(#clip-path)"
            >
              <rect
                id="Rectangle_7991"
                data-name="Rectangle 7991"
                width="34.773"
                height="34.773"
                transform="matrix(0.645, -0.764, 0.764, 0.645, -12.164, 14.401)"
                fill="url(#linear-gradient)"
              />
            </g>
          </g>
          <g
            id="Group_45290"
            data-name="Group 45290"
            transform="translate(0 0)"
          >
            <g
              id="Group_45289"
              data-name="Group 45289"
              clip-path="url(#clip-path-2)"
            >
              <path
                id="Path_58565"
                data-name="Path 58565"
                d="M22.814,11a.848.848,0,1,0,.848.848A.848.848,0,0,0,22.814,11"
                transform="translate(-6.647 -3.328)"
                fill="#fff"
              />
              <path
                id="Path_58566"
                data-name="Path 58566"
                d="M16.23,12.558a3.562,3.562,0,1,0,3.562,3.562,3.566,3.566,0,0,0-3.562-3.562m0,
                5.844a2.282,2.282,0,1,1,2.282-2.282A2.285,2.285,0,0,1,16.23,18.4"
                transform="translate(-3.833 -3.8)"
                fill="#fff"
              />
              <path
                id="Path_58567"
                data-name="Path 58567"
                d="M17.44,21.76H11.665A4.349,4.349,0,0,1,7.32,17.415V11.64A4.349,4.349,0,0,1,
                11.665,7.3H17.44a4.35,4.35,0,0,1,4.345,4.345v5.776A4.35,4.35,0,0,1,17.44,
                21.76m-5.775-13.1A2.987,2.987,0,0,0,8.681,11.64v5.776A2.987,2.987,0,0,0,11.665,
                20.4H17.44a2.988,2.988,0,0,0,2.985-2.984V11.64A2.988,2.988,0,0,0,17.44,8.656Z"
                transform="translate(-2.215 -2.207)"
                fill="#fff"
              />
            </g>
          </g>
        </svg>
      </a>
      <a
        target="_blank"
        href="https://www.youtube.com/channel/UC7iopEm9A8ATr8kuGGFFFVg"
        class="mr-3"
        aria-label="Vizitați canalul nostru de youtube"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          id="Group_45293"
          data-name="Group 45293"
          width="25"
          height="25"
          viewBox="0 0 24.675 24.675"
        >
          <defs>
            <clipPath id="clip-path">
              <rect
                id="Rectangle_7993"
                data-name="Rectangle 7993"
                width="24.675"
                height="24.675"
                fill="none"
              />
            </clipPath>
          </defs>
          <g
            id="Group_45292"
            data-name="Group 45292"
            transform="translate(0 0)"
            clip-path="url(#clip-path)"
          >
            <path
              id="Path_58568"
              data-name="Path 58568"
              d="M24.675,12.337A12.337,12.337,0,1,1,12.337,0,12.337,12.337,0,0,1,24.675,12.337"
              transform="translate(0 0)"
              fill="red"
            />
            <path
              id="Path_58569"
              data-name="Path 58569"
              d="M21.3,13.879a2.963,2.963,0,0,0-2.963-2.963H10.766A2.963,2.963,0,0,0,7.8,
              13.879V17.4a2.963,2.963,0,0,0,2.963,2.963h7.576A2.963,2.963,0,0,0,21.3,17.4Zm-4.455,
              2.027-3.4,1.681c-.133.072-.586-.024-.586-.176V13.96c0-.153.456-.25.589-.174l3.252,
              1.769c.137.077.28.275.142.349"
              transform="translate(-2.361 -3.303)"
              fill="#fff"
            />
          </g>
        </svg>
      </a>
    </div>
  </header>
</template>

<script>
import MobileNavigation from './headerComponent/MobileNavigation.vue';

export default {
  name: 'HeaderSTB',
  components: { MobileNavigation },
  data() {},
};
</script>

<style scoped></style>
