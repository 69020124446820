import { createApp } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faGlobe, faShop, faCommentDots, faBars, faXmark,
} from '@fortawesome/free-solid-svg-icons';
import './index.css';
import App from './App.vue';

library.add(faGlobe, faShop, faCommentDots, faBars, faXmark);
createApp(App).component('fa', FontAwesomeIcon).mount('#app');
