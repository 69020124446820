<template>
  <!-- Hamburger icon -->
  <div class="cursor-pointer lg:hidden" @click="showMobileMenu = true">
    <fa icon="bars" />
  </div>
  <!-- Mobile menu -->
  <div class="mobile-menu-holder sidebar">
    <div class="sidebar-backdrop" @click="closeMobileMenu"></div>
    <div class="sidebar-content">
      <div class="flex items-center">
        <button class="btn btn-gray btn-ghost btn-fixed -ml-2" @click="closeMobileMenu">
          <fa icon="xmark" class="text-lg"/>
        </button>
      </div>
      <ul class="flex flex-col space-y-3">
        <li class="rounded-lg overflow-hidden">
          <ul class="flex flex-col divide-y-2">
            <li>
              <a class="btn btn-white btn-menu" href="https://www.stanleybet.ro/pariuri-sportive">
                <span class="icon icon-xs">
                  <img
                    alt="sport icon"
                    src="../../assets/sidebar-icons/sport.ico.svg"
                    width="24" height="24"
                  />
                </span>
                <span title="sport">Sport</span>
              </a>
            </li>
            <li>
              <a class="btn btn-white btn-menu" href="https://www.stanleybet.ro/pariuri-live">
                <div>
                  <img
                    alt="live icon"
                    src="../../assets/sidebar-icons/live.ico.svg"
                    width="24" height="24"
                  />
                </div>
                <span title="live">Live</span>
              </a>
            </li>
            <li>
              <a class="btn btn-white btn-menu" href="https://www.stanleybet.ro/casino">
                <div>
                  <img
                    alt="casino icon"
                    src="../../assets/sidebar-icons/slots.ico.svg"
                    width="24" height="24"
                  />
                </div>
                <span title="casino">Casino</span>
              </a>
            </li>
            <li>
              <a class="btn btn-white btn-menu" href="https://www.stanleybet.ro/casino-live">
                <div>
                  <img
                    alt="casino live icon"
                    src="../../assets/sidebar-icons/live_casino.ico.svg"
                    width="24" height="24"
                  />
                </div>
                <span title="casino live">Casino Live</span>
              </a>
            </li>
            <li>
              <a class="btn btn-white btn-menu" href="https://www.stanleybet.ro/virtuale">
                <div>
                  <img
                    alt="loterii icon"
                    src="../../assets/sidebar-icons/bingo.ico.svg"
                    width="24" height="24"
                  />
                </div>
                <span title="virtuale">Virtuale</span>
              </a>
            </li>
            <li>
              <a class="btn btn-white btn-menu" href="https://www.stanleybet.ro/loterii">
                <div>
                  <img
                    alt="loterii icon"
                    src="../../assets/sidebar-icons/virtuals.ico.svg"
                    width="24" height="24"
                  />
                </div>
                <span title="loterii">Loterii</span>
              </a>
            </li>
          </ul>
        </li>
        <li class="rounded-lg overflow-hidden">
          <ul class="flex flex-col divide-y-2">
            <li>
              <a class="btn btn-white btn-menu" href="https://www.stanleybet.ro/promo">
                <div>
                  <img
                    alt="promotii icon"
                    src="../../assets/sidebar-icons/promotions.ico.svg"
                    width="24" height="24"
                  />
                </div>
                <span title="promoții">Promoții</span>
              </a>
            </li>
          </ul>
        </li>
        <li class="rounded-lg overflow-hidden">
          <ul class="flex flex-col divide-y-2">
            <li>
              <a class="btn btn-white btn-menu" href="https://www.stanleybet.ro/ajutor">
                <div>
                  <img
                    alt="casino icon"
                    src="../../assets/sidebar-icons/help.ico.svg"
                    width="24" height="24"
                  />
                </div>
                <span title="ajutor">Ajutor</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>

      <ul class="-mx-4 text-left">
        <li>
          <a class="block border-l-4 border-l-transparent px-4 py-2 font-medium text-neutral-700 hover:bg-white hover:text-primary-500" href="https://www.stanleybet.ro/faq"
            ><span title="Întrebări frecvente"> Întrebări frecvente</span></a
          >
        </li>
        <li>
          <a class="block border-l-4 border-l-transparent px-4 py-2 font-medium text-neutral-700 hover:bg-white hover:text-primary-500" href="https://www.stanleybet.ro/terms"
            ><span title="Termeni și conditii"> Termeni și Condiții</span></a
          >
        </li>
        <li>
          <a class="block border-l-4 border-l-transparent px-4 py-2 font-medium text-neutral-700 hover:bg-white hover:text-primary-500"  href="https://www.stanleybet.ro/regulamente"
            ><span title="Regulamente"> Regulamente</span></a
          >
        </li>
        <li>
          <a class="block border-l-4 border-l-transparent px-4 py-2 font-medium text-neutral-700 hover:bg-white hover:text-primary-500" href="https://www.stanleybet.ro/responsiblegaming"
            ><span title="Joc Responsabil"> Joc responsabil</span></a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileNavigation',
  data() {
    return {
      showMobileMenu: false,
    };
  },
  methods: {
    closeMobileMenu() {
      this.showMobileMenu = false;
    },
  },
  watch: {
    showMobileMenu(newValue) {
      if (newValue) {
        document.getElementsByTagName('body')[0].classList.add('sidebar-open');
      } else {
        document
          .getElementsByTagName('body')[0]
          .classList.remove('sidebar-open');
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
