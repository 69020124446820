<template>
  <footer class="text-white">
    <div class="bg-neutral-900 pt-16">
      <div class="container mx-auto px-4 xl:max-w-[1360px]">
        <div class="mb-10 grid grid-cols-1 gap-20 lg:grid-cols-2 xl:grid-cols-8 text-left">
          <div class="flex flex-col space-y-5 lg:col-span-1 xl:col-span-3">
            <div class="border-b border-b-neutral-700 pb-5">
              <h3 class="text-lg">Linkuri utile</h3>
            </div>
            <div class="grid grid-cols-2 gap-5 md:grid-cols-3 md:gap-4">
              <div class="flex flex-col space-y-5">
                <div>
                  <a
                    class="btn btn-link btn-white font-normal tracking-tight p-0"
                    href="https://www.stanleybet.ro/intrebari-frecvente"
                  >
                    Întrebări frecvente
                  </a>
                </div>
                <div>
                  <a
                    class="btn btn-link btn-white font-normal tracking-tight p-0"
                    href="https://www.stanleybet.ro/depuneri-si-retrageri"
                  >
                    Depuneri & retrageri
                  </a>
                </div>
                <div>
                  <a
                    class="btn btn-link btn-white font-normal tracking-tight p-0"
                    href="https://www.stanleybet.ro/termeni-si-conditii"
                  >
                    Terms & conditions
                  </a>
                </div>
                <div>
                  <a
                    class="btn btn-link btn-white font-normal tracking-tight p-0"
                    href="https://www.stanleybet.ro/regulamente"
                  >
                    Regulamente
                  </a>
                </div>
              </div>
              <div class="order-3 flex flex-col space-y-5 md:order-2">
                <div>
                  <a
                    class="btn btn-link btn-white font-normal tracking-tight p-0"
                    href="https://www.stanleybet.ro/joc-responsabil"
                  >
                    Joc responsabil
                  </a>
                </div>
                <div>
                  <a
                    class="btn btn-link btn-white font-normal tracking-tight p-0"
                    href="https://www.stanleybet.ro/protectia-datelor"
                  >
                    Protecția datelor
                  </a>
                </div>
                <div>
                  <a
                    class="btn btn-link btn-white font-normal tracking-tight p-0"
                    href="https://www.stanleybet.ro/despre-noi"
                  >
                    Despre noi
                  </a>
                </div>
                <div>
                  <a
                    class="btn btn-link btn-white font-normal tracking-tight p-0"
                    href="https://www.stanleybet.ro/cookies"
                  >
                    Cookies
                  </a>
                </div>
              </div>
              <div class="order-2 row-span-2 flex flex-col space-y-5 md:order-3 md:row-span-1">
                <div>
                  <a
                    href="https://www.stanleybet.ro/blog/ghiduri/"
                    target="_blank"
                    class="btn btn-link btn-white font-normal tracking-tight p-0"
                  >
                    Ghid de pariere
                  </a>
                </div>
                <div>
                  <a
                    class="btn btn-link btn-white font-normal tracking-tight p-0"
                    href="https://www.stanleybet.ro/contact"
                  >
                    Contact
                  </a>
                </div>
              </div>
            </div>
            <div>
              <button type="button" class="btn btn-xl btn-white btn-outline font-normal">
                <a href="javascript:void(zE('webWidget', 'open'))" title="Support Live">
                  <fa class="mr-4" icon="comment-dots" />
                  <span>Support Live</span>
                </a>
              </button>
            </div>
          </div>
          <div
            class="order-2 flex flex-col space-y-5 lg:order-3 lg:col-span-2 xl:order-2 xl:col-span-3"
          >
            <div class="border-b border-b-neutral-700 pb-5">
              <h3 class="text-lg">Despre Stanleybet</h3>
            </div>
            <div class="flex flex-col space-y-6 text-sm text-white/90">
              <p>
                Stanleybet.ro este deținut și operat integral de Megabet International SRL cu sediul
                în România, București, Bd. Tudor Vladimirescu, Nr. 29A, AFI Tech Park 1, Etaj 5, și
                capital social de
                {{ footer.socialCapital.online }} LEI.
              </p>
              <p>
                <span class="block">Poți juca numai dacă ai cel puțin 18 ani.</span>
                <span>
                  Jocurile de noroc pot deveni o problemă cu consecințe serioase dacă nu sunt
                  practicate responsabil.
                </span>
              </p>
              <p>
                <span>
                  Stanleybet încurajează jocul responsabil! Pentru ajutor în probleme legate de
                  adicție, te rugăm intră aici
                  <a
                    href="https://clinica-aliat.ro/jocuri-de-noroc/"
                    target="_blank"
                    class="text-primary-500 hover:text-primary-600 hover:underline"
                  >
                    clinica-aliat.
                  </a>
                </span>
              </p>
            </div>
          </div>
          <div
            class="order-3 flex flex-col space-y-5 lg:order-2 lg:col-span-1 xl:order-3 xl:col-span-2"
          >
            <div class="border-b border-b-neutral-700 pb-5">
              <h3 class="text-lg">Metode de plată disponibile</h3>
            </div>
            <div class="grid grid-cols-3 gap-3">
              <span class="flex h-14 items-center justify-center rounded bg-neutral-800">
                <img
                  class="max-h-[80%] max-w-[80%]"
                  :src="paymentMethods.cash"
                  alt="Cash at Stanleybet agencies"
                  width="26"
                  height="26"
                  tooltip="Cash at Stanleybet agencies"
                />
              </span>
              <span class="flex h-14 items-center justify-center rounded bg-neutral-800">
                <img
                  class="max-h-[80%] max-w-[80%]"
                  :src="paymentMethods.revolut"
                  alt="Revolut"
                  tooltip="Revolut"
                  width="38"
                  height="28"
                />
              </span>
              <span class="flex h-14 items-center justify-center rounded bg-neutral-800">
                <img
                  class="max-h-[80%] max-w-[80%]"
                  :src="paymentMethods.mastercard"
                  alt="Mastercard"
                  width="44"
                  height="29"
                  tooltip="Mastercard"
                />
              </span>
              <span class="flex h-14 items-center justify-center rounded bg-neutral-800">
                <img
                  class="max-h-[80%] max-w-[80%]"
                  :src="paymentMethods.visa"
                  alt="Visa"
                  tooltip="Visa"
                  width="55"
                  height="18"
                />
              </span>
              <span class="flex h-14 items-center justify-center rounded bg-neutral-800">
                <img
                  class="max-h-[80%] max-w-[80%]"
                  :src="paymentMethods.paysafecard"
                  alt="paysafecard"
                  width="60"
                  height="30"
                  tooltip="paysafecard"
                />
              </span>
              <span class="flex h-14 items-center justify-center rounded bg-neutral-800">
                <img
                  class="max-h-[80%] max-w-[80%]"
                  :src="paymentMethods.skrill"
                  alt="Skrill"
                  tooltip="Skrill"
                  width="50"
                  height="17"
                />
              </span>
              <span class="flex h-14 items-center justify-center rounded bg-neutral-800">
                <img
                  class="max-h-[80%] max-w-[80%]"
                  :src="paymentMethods.neteller"
                  alt="Neteller"
                  tooltip="Neteller"
                  width="80"
                  height="13"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="border-t border-t-neutral-700 py-4 text-xs text-white/60">
        <div class="container mx-auto px-4 xl:max-w-[1360px]">
          <div class="mb-4 flex items-start justify-center space-x-2">
            <fa icon="globe" class="text-base" />
            <p class="mt-1 text-left">
              <span class="text-white"> Online: </span>
              Megabet International SRL - Bd. Tudor Vladimirescu Nr. 29A, Afi Tech Park 1, Et.5,
              Sector 5, Bucharest | J40/15667/2020 | CIF 43341869 | CS
              {{ footer.socialCapital.online }} LEI |
              <a
                href="/licenta-megabet-international.pdf"
                target="_blank"
                class="text-white hover:text-white/80 hover:underline"
              >
                L1213822W001268
              </a>
            </p>
          </div>
          <div class="flex items-start justify-center space-x-2">
            <fa icon="shop" class="text-base" />
            <p class="mt-1 text-left">
              <span class="text-white"> Agenții: </span>
              WESTGATE ROMANIA SRL - Bd. Tudor Vladimirescu Nr. 29A, Afi Tech Park 1, Et.5, Sector
              5, Bucharest | J40/20246/2004 | CIF 17017651 | CS
              {{ footer.socialCapital.agencies }} LEI |
              <a
                href="/2021-licenta-autorizatie-pariuri-portret-23-aug.pdf"
                target="_blank"
                class="text-white hover:text-white/80 hover:underline"
              >
                L1160532F000307
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center bg-black p-4">
      <div class="flex flex-col items-center justify-center md:flex-row md:flex-wrap md:space-x-6">
        <div class="order-last grid grid-cols-2 gap-3 md:order-first md:w-auto md:gap-6">
          <a href="https://anpc.ro/ce-este-sal/" target="_blank">
            <img
              :src="footer.ancp"
              class="w-[200px] max-w-full rounded border-2 border-white bg-white"
              width="200"
              height="41"
              alt="ANPC - Alternative Dispute Resolution"
            />
          </a>
          <a
            href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show"
            target="_blank"
          >
            <img
              :src="footer.sol"
              class="w-[200px] max-w-full rounded border-2 border-white bg-white"
              width="200"
              height="41"
              alt="Online Dispute Resolution"
            />
          </a>
        </div>
        <div class="grid grid-cols-3 gap-6">
          <a href="https://jocresponsabil.ro/" target="_blank">
            <img
              :src="footer.responsibleGaming"
              class="h-8 w-8"
              alt="Responsible gaming"
              width="32"
              height="32"
            />
          </a>
          <a href="https://www.stanleybet.ro/joc-responsabil" target="_blank">
            <img :src="footer.icon18" class="h-8 w-8" alt="18+" width="32" height="32" />
          </a>
          <a href="http://onjn.gov.ro/" target="_blank">
            <img :src="footer.onjn" class="h-8 w-8" alt="ONJN" width="32" height="32" />
          </a>
        </div>
        <p class="max-w-xl py-4 text-xs text-white/60 lg:py-0">
          ©Stanleybet.ro - Toate drepturile rezervate!
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterSTB',
  components: {},
  data() {
    return {
      paymentMethods: {
        cash: 'https://d1rgkl0dfq63ul.cloudfront.net/landing/footer/payment_cash_white.svg',
        revolut: 'https://d1rgkl0dfq63ul.cloudfront.net/landing/footer/payment_revolut_white.svg',
        mastercard:
          'https://d1rgkl0dfq63ul.cloudfront.net/landing/footer/payment_mastercard_white.svg',
        visa: 'https://d1rgkl0dfq63ul.cloudfront.net/landing/footer/payment_visa_white.svg',
        paysafecard:
          'https://d1rgkl0dfq63ul.cloudfront.net/landing/footer/payment_paysafecard_white.svg',
        skrill: 'https://d1rgkl0dfq63ul.cloudfront.net/landing/footer/payment_skrill_white.svg',
        neteller: 'https://d1rgkl0dfq63ul.cloudfront.net/landing/footer/payment_neteller_white.svg',
      },
      footer: {
        ancp: 'https://d1rgkl0dfq63ul.cloudfront.net/landing/footer/anpc.svg',
        icon18: 'https://d1rgkl0dfq63ul.cloudfront.net/landing/footer/icon_18%2B.svg',
        onjn: 'https://d1rgkl0dfq63ul.cloudfront.net/landing/footer/icon_onjn.svg',
        responsibleGaming:
          'https://d1rgkl0dfq63ul.cloudfront.net/landing/footer/icon_responsible_gaming.svg',
        sol: 'https://d1rgkl0dfq63ul.cloudfront.net/landing/footer/sol.svg',
        socialCapital: {
          agencies: '',
          online: '',
        },
      },
    };
  },
  async created() {
    const response = await fetch(process.env.VUE_APP_FOOTER_API_URL);
    const data = await response.json();
    this.footer.socialCapital = data.socialCapital;
  },
};
</script>

<style scoped></style>
