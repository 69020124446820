<template>
  <HeaderSTB />
  <PageContent />
  <FooterSTB />
</template>

<script>
import PageContent from './components/PageContent.vue';
import HeaderSTB from './components/HeaderSTB.vue';
import FooterSTB from './components/FooterSTB.vue';

export default {
  name: 'App',
  components: {
    PageContent,
    HeaderSTB,
    FooterSTB,
  },
};
</script>

<style>

#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
