<template>
  <div class="hero-section container">
    <div class="image-holder club-logo">
      <img
        alt="stanley club logo"
        src="https://d1rgkl0dfq63ul.cloudfront.net/pages/club/stanley_club_logo.svg"
        width="446"
        height="108"
      />
    </div>
    <div class="image-holder mobile">
      <img
        alt="key visual"
        src="https://d1rgkl0dfq63ul.cloudfront.net/pages/club/kv.webp"
      />
    </div>
    <div class="image-holder desktop">
      <img
        alt="key visual"
        src="https://d1rgkl0dfq63ul.cloudfront.net/pages/club/kv_desktop.webp"
        width="954"
        height="637"
      />
    </div>
  </div>
  <div class="container m-auto">
    <div class="card-holder">
      <div class="card">
        <div class="title-holder">
          <img
            src="https://d1rgkl0dfq63ul.cloudfront.net/pages/club/badge_icon.webp"
            width="32"
            height="32"
            alt="Loialitate"
          />
          <h2>Stanleybet îți răsplătește loialitatea!</h2>
        </div>
        <div class="description">
          <p>
            Devino membru Stanleybet Club și primești acces la o mulțime de
            beneficii și promoții gândite special pentru tine.
          </p>
          <p>
            <strong>Intră în club și profită de toate avantajele!</strong>
          </p>
        </div>
      </div>
      <div class="card">
        <div class="title-holder">
          <img
            src="https://d1rgkl0dfq63ul.cloudfront.net/pages/club/help_icon.webp"
            width="32"
            height="32"
            alt="Cum intri"
          />
          <h2>Cum intri in Stanleybet Club:</h2>
        </div>
        <div class="description">
          <p>
            <strong>1.</strong> E simplu: vii în orice agenție Stanleybet și
            soliciți înscrierea la ghișeu
          </p>
          <p>
            <strong>2.</strong> Mai trebuie să ne dai acordul și să confirmi
            numărul de telefon
          </p>
          <p>
            <strong>3.</strong> Și gata, primești cardul de membru Stableybet
            Club pe loc.
          </p>
        </div>
      </div>
      <div class="card">
        <div class="title-holder">
          <img
            src="https://d1rgkl0dfq63ul.cloudfront.net/pages/club/star_icon.webp"
            width="32"
            height="32"
            alt="Club Stanleybet"
          />
          <h2>Beneficii Stanleybet Club:</h2>
        </div>
        <div class="description">
          <p>
            <strong>Plăți mega rapide</strong><br />Când ai de încasat un
            câștig, prezinți cardul de membru și actul de identitate la casierie
            și primești banii instant.
          </p>
          <p>
            <strong>În curând!</strong><br />Premii zilnice și alte mega
            surprize. Fii pe fază, revenim cu detalii!
          </p>
        </div>
      </div>
    </div>
    <div class="tc-holder">
      <a href="/Regulament_Stanleybet Club.pdf" target="blank"
        >Consultă regulamentul</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageContent',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Hero section */
.hero-section {
  @apply mt-[26px] lg:flex lg:mx-auto lg:w-[100%] lg:mt-[-20px] mx-auto;
}

.image-holder.club-logo {
  @apply px-4 lg:px-0 w-full max-w-[60%] xxl:max-w-[40%] m-auto;
}

.image-holder {
  @apply flex justify-center lg:justify-start;
}

.image-holder.mobile {
  @apply mt-[-30px] xs:mt-[-40px] sm:mt-[-60px] md:hidden;
}

.image-holder.mobile img {
  @apply w-full;
}

.image-holder.desktop {
  @apply hidden mt-[-80px] md:flex lg:mt-0;
}

/* Card holders */
.card-holder {
  @apply lg:flex lg:gap-[41px] lg:mb-[63px];
}

.card {
  @apply mx-[10px] bg-white rounded-[8px] mb-[26px] pt-[23px] px-[17px] pb-[13px];
  @apply lg:mx-0 lg:pt-[28px] lg:px-[52px] lg:pb-[48px] lg:w-1/3 lg:flex lg:flex-col;
}

.title-holder {
  @apply flex;
}

.title-holder img {
  @apply h-fit mr-[8px] ml-[-6px];
}

.card h2 {
  @apply text-[19px] leading-[30px] font-bold mb-[18px];
}

.card .description p {
  @apply mb-[14px];
}

/* TC section */
.tc-holder {
  @apply text-center mb-[33px];
}

.tc-holder a {
  @apply text-[16px] leading-[16px] text-greyTxt underline;
}
</style>
